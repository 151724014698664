@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 28px !important;
  font-size: 1rem !important;
  /* background: #E5E5E5; */
  background-color: #f7f6f0 !important;
  background: #f7f6f0;
  color: #2b2a29;
}

/************** Header CSS ******************/
header {
  width: 100%;
  position: relative;
}

header .navbar {
  padding: 20px 15px;
  background: #f7b719 !important;
  width: 100%;
}

header .navbar-nav .nav-item {
  padding: 0 22px;
}

header .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

header .navbar-nav .nav-item .nav-link {
  color: #2b2a29;
  font-weight: 600;
  padding: 0;
  position: relative;
  transition: all 0.3s ease;
}

header .navbar-nav .nav-item .nav-link:hover {
  color: #e54f22;
}

header .sticky {
  position: fixed;
  z-index: 99;
  padding: 8px 15px;
  transition: all 0.5s ease;
  -webkit-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  -moz-box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
}

.nonLanding header .sticky {
  position: relative !important;
}


/************** Header CSS ******************/

/************** Footer CSS ******************/
footer {
  background: #2b2a29;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

footer .copyright {
  margin: 0;
  color: #fff;
  font-size: 14px;
}

footer .copyright-text {
  margin: 0;
  color: #fff;
  font-size: 14px;
  border-top: 1px solid #ecf0f2;
}

footer h4 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0 18px;
  font-weight: 600;
}

footer ul {
  list-style-type: none;
}

footer ul li {
  color: #fff;
  list-style-type: none;
}

footer ul li a {
  color: #ffffff;
}

footer ul li a:hover {
  color: #e54f22;
}

footer .social-links {
  flex-wrap: wrap;
}

footer .social-links li {
  background-color: #ffffff;
  width: 40px;
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  padding: 8px 5px 8px;
  transition: 0.2s;
  font-size: 22px;
}

footer .contact-us li {
  flex-wrap: wrap;
  margin-bottom: 8px;
}

footer .contact-us li span:first-child {
  width: 10%;
}

footer .contact-us li span:last-child {
  width: 90%;
}

footer .contact-us li span i {
  font-size: 20px;
}

footer .contact-us li .fa-map-marker {
  font-size: 26px;
}

.fa-facebook {
  color: #3a559f;
}

.fa-google-plus-official {
  color: #ca4029;
}

.fa-instagram {
  color: #c63a86;
}

.fa-linkedin {
  color: #0077b4;
}

.fa-twitter {
  color: #62b6eb;
}

/************** Footer CSS ******************/

/************** Media Query CSS ******************/
@media only screen and (max-width: 991px) {
  header .navbar-nav .nav-item {
    padding: 0 10px;
  }

  footer .col-lg-4 {
    margin-bottom: 30px;
  }

  footer .col-lg-4:last-child {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 960px) {

  .navbar-toggler {
    padding: 0;
  }

  .navbar-toggler:focus {
    outline: 0;
  }

  .navbar-toggler-icon {
    height: auto;
    width: auto;
    color: #e54f22;
  }

  button.navbar-toggler.collapsed .fa-bars {
    display: block;
  }

  button.navbar-toggler.collapsed .fa-times {
    display: none;
  }

  button .navbar-toggler .fa-times {
    display: block;
  }

  button.navbar-toggler .fa-bars {
    display: none;
  }

  .navbar-collapse {
    background: #e54f22;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 99;
  }

  .navbar-collapse ul .nav-item .nav-link {
    color: #ffffff;
  }

  header .navbar-nav .nav-item .nav-link:hover {
    color: #ffffff;
  }

  header .navbar-nav .nav-item {
    padding: 5px 10px;
  }

  header .sticky .navbar-nav .nav-item .nav-link {
    color: #fff;
  }
}

@media only screen and (max-width: 480px) {
  footer h4 {
    font-size: 22px;
  }
}

.muilist-padding {
  padding: '0rem auto';
}

#tableTitle {
  color: #a12621;
}