body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "DM Sans", "Roboto" "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notes-card {
  background-color: #e9ecef;
  padding: 1.5% 2%;
  border-radius: 5px;
  margin: 2%;
  cursor: pointer;
  font-size: 15px;
}

.arr-right .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  font-weight: bold;
  font-size: 20px;
  color: #000;
}

.breadcrumb {
  background-color: transparent !important;
  border-radius: 0;
  border-bottom: 2px solid lightgrey;
}

.breadcrumb a {
  color: black;
  font-weight: bold;
  font-size: large;
}

.notes-card i {
  font-size: 24px;
  margin-right: 2%;
}

.notes-back-btn {
  font-size: 12px;
  background: lightgray;
  border: none;
  border-radius: 5px;
  color: darkred;
  font-weight: bold;
  padding: 2px 20px;
  cursor: pointer;
}

.react-pdf__Document {
  overflow-y: scroll;
  height: 95vh !important;
}

.react-pdf__Page__canvas {
  width: -webkit-fill-available !important;
}

.mainButton {
  background-color: #fd9f01 !important;
  color: white !important;
  text-decoration: none;
  border: 1px solid transparent;
}

.mainButton:hover {
  background-color: #fd9f01 !important;
  opacity: 0.8 !important;
  text-decoration: none;
}

.mainButton:disabled {
  opacity: 0.7 !important;
  text-decoration: none;
}
