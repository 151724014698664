.thank-you {
  min-height: 95vh;
  font-size: 1rem;
  font-family: "DM Sans";
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbd741;
}

.inside-page {
  width: 100%;
  max-width: 520px;
  padding: 15px;
  background-color: white;
  border-radius: 1.5rem;
  padding: 2rem 3rem 1rem 3rem;
  margin: 5%;
}

.thank-you .img-logo {
  width: 80%;
  height: auto;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .thank-you .img-logo {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .thank-you .img-logo {
    width: 100%;
  }
}

.thank-you .header {
  font-weight: 700;
}

.thank-you .button-div {
  display: flex;
  justify-content: center;
}

.thank-you button {
  /* border-color: #ffde17;
  background-color: #ffde17; */
  border-radius: 1rem;
  margin: 1rem;
  width: 80%;
}

.thank-you button h1 {
  margin: 0;
}

.thank-you .link,
.thank-you .link:hover,
.thank-you .link:focus,
.thank-you .link:active {
  text-decoration: none;
  color: inherit;
}
