.contactButton {
  color: #029d00;
}

.contactButton:focus {
  outline: none !important;
  border: none !important;
  color: #fbd741;
}
.contactButton span svg {
  font-size: 2.5rem;
  color: #fbd741;
  background: black;
  border-radius: 30px;
}
