@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "DM Sans", "Roboto" "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.notes-card {
  background-color: #e9ecef;
  padding: 1.5% 2%;
  border-radius: 5px;
  margin: 2%;
  cursor: pointer;
  font-size: 15px;
}

.arr-right .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  font-weight: bold;
  font-size: 20px;
  color: #000;
}

.breadcrumb {
  background-color: transparent !important;
  border-radius: 0;
  border-bottom: 2px solid lightgrey;
}

.breadcrumb a {
  color: black;
  font-weight: bold;
  font-size: large;
}

.notes-card i {
  font-size: 24px;
  margin-right: 2%;
}

.notes-back-btn {
  font-size: 12px;
  background: lightgray;
  border: none;
  border-radius: 5px;
  color: darkred;
  font-weight: bold;
  padding: 2px 20px;
  cursor: pointer;
}

.react-pdf__Document {
  overflow-y: scroll;
  height: 95vh !important;
}

.react-pdf__Page__canvas {
  width: -webkit-fill-available !important;
}

.mainButton {
  background-color: #fd9f01 !important;
  color: white !important;
  text-decoration: none;
  border: 1px solid transparent;
}

.mainButton:hover {
  background-color: #fd9f01 !important;
  opacity: 0.8 !important;
  text-decoration: none;
}

.mainButton:disabled {
  opacity: 0.7 !important;
  text-decoration: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  line-height: 28px !important;
  font-size: 1rem !important;
  /* background: #E5E5E5; */
  background-color: #f7f6f0 !important;
  background: #f7f6f0;
  color: #2b2a29;
}

/************** Header CSS ******************/
header {
  width: 100%;
  position: relative;
}

header .navbar {
  padding: 20px 15px;
  background: #f7b719 !important;
  width: 100%;
}

header .navbar-nav .nav-item {
  padding: 0 22px;
}

header .navbar-nav .nav-item:last-child {
  padding-right: 0;
}

header .navbar-nav .nav-item .nav-link {
  color: #2b2a29;
  font-weight: 600;
  padding: 0;
  position: relative;
  transition: all 0.3s ease;
}

header .navbar-nav .nav-item .nav-link:hover {
  color: #e54f22;
}

header .sticky {
  position: fixed;
  z-index: 99;
  padding: 8px 15px;
  transition: all 0.5s ease;
  box-shadow: 0 1px 5px 0 rgba(41, 85, 115, 0.21);
}

.nonLanding header .sticky {
  position: relative !important;
}


/************** Header CSS ******************/

/************** Footer CSS ******************/
footer {
  background: #2b2a29;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

footer .copyright {
  margin: 0;
  color: #fff;
  font-size: 14px;
}

footer .copyright-text {
  margin: 0;
  color: #fff;
  font-size: 14px;
  border-top: 1px solid #ecf0f2;
}

footer h4 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0 18px;
  font-weight: 600;
}

footer ul {
  list-style-type: none;
}

footer ul li {
  color: #fff;
  list-style-type: none;
}

footer ul li a {
  color: #ffffff;
}

footer ul li a:hover {
  color: #e54f22;
}

footer .social-links {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

footer .social-links li {
  background-color: #ffffff;
  width: 40px;
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  padding: 8px 5px 8px;
  transition: 0.2s;
  font-size: 22px;
}

footer .contact-us li {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 8px;
}

footer .contact-us li span:first-child {
  width: 10%;
}

footer .contact-us li span:last-child {
  width: 90%;
}

footer .contact-us li span i {
  font-size: 20px;
}

footer .contact-us li .fa-map-marker {
  font-size: 26px;
}

.fa-facebook {
  color: #3a559f;
}

.fa-google-plus-official {
  color: #ca4029;
}

.fa-instagram {
  color: #c63a86;
}

.fa-linkedin {
  color: #0077b4;
}

.fa-twitter {
  color: #62b6eb;
}

/************** Footer CSS ******************/

/************** Media Query CSS ******************/
@media only screen and (max-width: 991px) {
  header .navbar-nav .nav-item {
    padding: 0 10px;
  }

  footer .col-lg-4 {
    margin-bottom: 30px;
  }

  footer .col-lg-4:last-child {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 960px) {

  .navbar-toggler {
    padding: 0;
  }

  .navbar-toggler:focus {
    outline: 0;
  }

  .navbar-toggler-icon {
    height: auto;
    width: auto;
    color: #e54f22;
  }

  button.navbar-toggler.collapsed .fa-bars {
    display: block;
  }

  button.navbar-toggler.collapsed .fa-times {
    display: none;
  }

  button .navbar-toggler .fa-times {
    display: block;
  }

  button.navbar-toggler .fa-bars {
    display: none;
  }

  .navbar-collapse {
    background: #e54f22;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 99;
  }

  .navbar-collapse ul .nav-item .nav-link {
    color: #ffffff;
  }

  header .navbar-nav .nav-item .nav-link:hover {
    color: #ffffff;
  }

  header .navbar-nav .nav-item {
    padding: 5px 10px;
  }

  header .sticky .navbar-nav .nav-item .nav-link {
    color: #fff;
  }
}

@media only screen and (max-width: 480px) {
  footer h4 {
    font-size: 22px;
  }
}

.muilist-padding {
  padding: '0rem auto';
}

#tableTitle {
  color: #a12621;
}
@media only screen and (max-width: 600px) {
	.meta-row span {
		font-size: 20px;
	}

	.meta-row .col-6 {
		font-size: 12px;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.meta-row span {
		font-size: 20px;
	}

	.meta-row .col-6 {
		font-size: 12px;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.meta-row span {
		font-size: 28px;
	}

	.meta-row .col-md-12 {
		font-size: 15px;
	}
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.meta-row span {
		font-size: 28px;
	}

	.meta-row .col-md-12 {
		font-size: 15px;
	}
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
	.meta-row span {
		font-size: 28px;
	}

	.meta-row .col-md-12 {
		font-size: 15px;
	}
}

.primary {
	background-color: #e44e22;
	color: white;
}

.navbar {
	box-shadow: 0 8px 6px -6px #ccc;
}

.navbar-brand {
	padding: 0;
}

.navbar-brand-img {
	max-width: 20vw;
	padding: 1%;
	padding-left: 1rem;
}

.nav-item #profile-icon {
	height: 3rem;
}

@media only screen and (max-width: 990px) {
	.nav-item #profile-icon {
		height: 2.5rem;
	}
}

@media only screen and (max-width: 800px) {
	.navbar-brand-img {
		max-width: 40vw;
		margin-left: 18%;
	}

	.nav-item #profile-icon {
		height: 2.5rem;
	}
}

@media only screen and (max-width: 500px) {
	.navbar-brand-img {
		max-width: 55vw;
		margin-left: 21%;
		padding-left: 0.1rem;
	}

	.nav-item #profile-icon {
		height: 2rem;
	}
}

@media only screen and (max-width: 400px) {
	.navbar-brand-img {
		max-width: 45vw;
		margin-left: 21%;
		padding-left: 0.1rem;
	}
}

/* navbar-brand-img-non-auth start*/

.navbar-brand-img-non-auth {
	max-width: 20vw;
	padding: 1%;
	padding-left: 1rem;
}

@media only screen and (max-width: 800px) {
	.navbar-brand-img-non-auth {
		max-width: 40vw;
	}
}

@media only screen and (max-width: 500px) {
	.navbar-brand-img-non-auth {
		max-width: 55vw;
		padding-left: 0.1rem;
	}

	.nav-item #profile-icon {
		height: 2rem;
	}
}

@media only screen and (max-width: 400px) {
	.navbar-brand-img-non-auth {
		max-width: 45vw;
		padding-left: 0.1rem;
	}
}

/* end*/

.nonLanding .navbar {
	padding: 0.5rem;
	/* height: 5%; */
}

.account-dropdown {
	min-width: 0rem;
	left: auto !important;
	right: 0 !important;
	border-radius: 16px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

	font-family: "DM Sans";
}

.nav-item .dropdown-item {
	color: rgba(80, 87, 128, 1);
	font-weight: 700;
}

.nav-item .dropdown-item:active {
	background-color: white;
}

.logout-button {
	font-weight: 700;
	font-family: "DM Sans";
}

.btnPrimary {
	background-color: #e44e22;
	color: white;
	padding: 2px 5px;
}

.btnPrimary:hover {
	background-color: #c33b13;
	text-transform: none;
	color: white;
	text-decoration: none;
}

.btnSecondary {
	border: 1px solid #2b2a29;
	padding: 2px 10px;
	color: #2b2a29;
	background-color: white;
}

.btnSecondary:hover {
	text-transform: none;
	text-decoration: none;
	background-color: lightgrey;
}

@media only screen and (max-width: 960px) {
	.btnSecondary,
	.btnPrimary {
		font-weight: 600;
		font-size: 0.875rem;
		margin: 5px 0px;
		border: 1px solid black;
		background-color: white;
		color: #e44e22;
	}

	.btnSecondary:hover {
		background-color: white;
	}
}

.laptop {
	line-height: 16px;
}

/* @media only screen and (max-width: 1200px) {
  .nav-item {
    padding: 0px 15px !important;
  }
} */

.referral-rewards {
	background: url(/static/media/reward.e8df1b1c.png) no-repeat;
	width: 17rem;
	height: 17rem;
	background-size: 100%;
	position: relative;
}

.value-label .row label {
	margin-bottom: 0rem;
}

.student-details td,
.student-details th {
	padding: 0.1rem 0.5rem;
	text-align: center;
	vertical-align: middle;
	font-weight: normal;
}

.student-details tr td:first-child {
	text-align: left !important;
}

.student-details tr {
	border: 1px solid beige;
	line-height: 30px;
}

.student-details thead {
	border: 1px solid #ffd296;
	font-weight: 600;
}

.delete-slot {
	width: 8rem;
}

.cancel-slot {
	width: 8rem;
	position: absolute !important;
	top: -4rem;
	left: 0rem;
	font-size: 11px !important;
}

.calender-instruct {
	margin: 1rem;
}

.grey-button {
	border: 1px solid #f96400 !important;
}

.yellow-button {
	background-color: #ffee00 !important;
}

.blue-button {
	background-color: #19aaaa !important;
}

.green-button {
	background-color: #00ff3a !important;
}

.red-button {
	background-color: crimson !important;
}

section .section-head {
	padding: 1rem;
	background-color: #ace5fa;
}

section .section-content {
	padding: 1rem;
}

.profile-img {
	position: absolute;
	height: 30rem;
	bottom: 0;
	right: 0;
	opacity: 0.2;
}

.referral-form .form-data {
	max-width: 65rem;
}

/* Teacher Batch Enrollment Card*/
/* @import url("https://fonts.googleapis.com/css?family=Muli&display=swap"); */

.course-body {
	/* font-family: "Muli", sans-serif; */
	font-family: "DM Sans", sans-serif;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.course {
	background-color: #fff3cd;
	border-radius: 10px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	display: -webkit-flex;
	display: flex;
	max-width: 100%;
	margin: 20px;
	overflow: hidden;
	width: 700px;
}

.course h6 {
	opacity: 0.6;
	margin: 0;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.course h2 {
	letter-spacing: 1px;
	margin: 10px 0;
}

#enrolled-batch-bg {
	background-color: #d4edda;
}

.course-meta {
	background: green;
	text-align: center;
	color: #fff;
}

.ribbon {
	margin: 0;
	padding: 0;
	background: green;
	color: white;
	padding: 0.5em 0;
	position: absolute;
	top: 0;
	right: 0;
	-webkit-transform: translateX(60%) translateY(-10%) rotate(-45deg);
	        transform: translateX(60%) translateY(-10%) rotate(-45deg);
	-webkit-transform-origin: top left;
	        transform-origin: top left;
}

@media only screen and (max-width: 600px) {
	.ribbon {
		margin: 0;
		padding: 0;
		background: green;
		color: white;
		padding: 0.5em 0;
		position: absolute;
		top: 0;
		right: 0;
		-webkit-transform: translateX(0%) translateY(-10%) rotate(-45deg);
		        transform: translateX(0%) translateY(-10%) rotate(-45deg);
		-webkit-transform-origin: top left;
		        transform-origin: top left;
	}
}

.ribbon:before,
.ribbon:after {
	content: "";
	position: absolute;
	top: 0;
	margin: 0 -1px;
	/* tweak */
	width: 100%;
	height: 100%;
	background: green;
}

.ribbon:before {
	right: 100%;
}

.ribbon:after {
	left: 100%;
}

.course-preview {
	background-color: #ffc107;
	color: #000;
	padding: 30px;
	max-width: 250px;
}

.course-preview-enrolled {
	background-color: green;
	color: #fff;
	padding: 30px;
	max-width: 250px;
}

.course-preview a,
.course-preview-enrolled a {
	color: #fff;
	display: inline-block;
	font-size: 12px;
	opacity: 0.6;
	text-decoration: none;
}

.course-info {
	padding: 30px;
	position: relative;
	width: 100%;
}

.course-btn {
	background-color: #ffc107;
	border: 0;
	border-radius: 50px;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
	color: #000;
	font-size: 16px;
	padding: 5px 25px;
	bottom: 30px;
	right: 30px;
	letter-spacing: 1px;
	cursor: pointer;
}

div.calendarDate {
	font-size: 1em;
	/*change calendarIcon size */
	display: block;
	position: relative;
	max-width: 3.5em;
	height: 3em;
	background-color: #fff;
	border-radius: 0.7em;
	-moz-border-radius: 0.7em;
	box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff,
		0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
	overflow: hidden;
}

div.calendarDate * {
	display: block;
	width: 100%;
	font-size: 0.8em;
	font-style: normal;
	font-weight: bold;
	text-align: center;
}

div.calendarDate em {
	position: absolute;
	bottom: 0em;
	color: #fff;
	padding-top: 0.2em;
	line-height: 15px;
	background-color: #19aaaa;
}

div.calendarDate span {
	font-size: 0.7em;
	letter-spacing: -0.05em;
	color: #2f2f2f;
	font-weight: 600;
}

.lecture-card-container .card {
	/* font-family: "Muli"; */
	font-family: "DM Sans";
	border-radius: 10px;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

#lectureCard {
	text-align: left;
}

.badge-info {
	box-shadow: 0 5px 5px rgb(11 7 7 / 20%);
	font-weight: 400;
}

.recording-div {
	border: 1px solid #d6d8d9;
	margin: 3%;
	padding: 1%;
	border-radius: 5px;
}

.recording-text {
	width: 80px;
	font-size: 10px;
	margin-top: -15px;
	margin-left: 5px;
	background: white;
}

.card > hr {
	margin: 3% 20%;
}

.meta-row {
	/* font-family: "Muli"; */
	font-family: "DM Sans";
	text-align: center;
}

/* Student DashBoard New */

/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap"); */

.studentDashboardNew {
	min-height: 90vh;
	font-family: "DM Sans";
}

.studentDashboardNew .aside {
	text-align: center;
	cursor: pointer;
	padding: 0;
	font-size: 1.2vw;
	background-color: white;
	/* align-items: center; */
}

@media only screen and (max-width: 767px) {
	.studentDashboardNew .aside {
		display: -webkit-flex;
		display: flex;
		/* flex-direction: row;
    justify-content: space-evenly; */
		font-size: 1.5vw;
		padding-top: 0;
		/* position: fixed; */
		position: absolute;
		z-index: 100;
		top: 3px;
		max-width: 20px;
		background-color: #f7b719;
		/*to solve the overlay issue with profile icon*/

		/* left: 5px; */
	}

	.studentDashboardNew .icons img {
		max-width: 50px;
	}
}

@media only screen and (max-width: 500px) {
	.studentDashboardNew .aside {
		font-size: 2.5vw;
	}
}
/* @media only screen and (min-width: 767px) {
  .studentDashboardNew .aside .nav-item:hover {
    background: rgba(255, 222, 23, 0.3);
  
  }
} */
.studentDashboardNew .aside .nav-item:hover {
	background: rgba(255, 222, 23, 0.3);
}

@media only screen and (max-width: 500px) {
	.studentDashboardNew .aside {
		font-size: 2.5vw;
	}
}

@media only screen and (max-width: 767px) {
	.studentDashboardNew .aside .navbar {
		padding: 1.2rem 1.2rem;
	}
}

@media only screen and (max-width: 405px) {
	.studentDashboardNew .aside .navbar {
		padding: 0.8rem 1.2rem;
	}
}

.studentDashboardNew .aside .nav-item:hover {
	background: rgba(255, 222, 23, 0.3);
}

/* .studentDashboardNew .aside .row{
  padding-top: 1%;
} */

.studentDashboardNew .aside .nav-item {
	padding: unset !important;
}

.studentDashboardNew .aside .navbar {
	margin: 0;
	box-shadow: none;
}

@media only screen and (min-width: 767px) {
	.studentDashboardNew .aside .navbar {
		padding: 0;
	}
}

.studentDashboardNew .aside .navbar-nav {
	-webkit-flex-direction: column;
	        flex-direction: column;
	background-color: white;

	/* height: 100vh; */
}

@media only screen and (max-width: 767px) {
	#navbarNavAltMarkup1 {
		width: 100vw;
		/* min-height: 100vh; */
		background-color: white;
		/* padding: 20px;*/
		/* border-radius: 0px 0px 40px 40px; */
	}

	/* #navbarNavAltMarkup1 .navbar-nav{
    border-radius: 0px 0px 40px 40px;
  }

  .studentDashboardNew .aside .nav-item:hover {
    border-radius: 0px 0px 40px 40px;
  
  } */
}

@media only screen and (max-width: 767px) {
	#navbarNavAltMarkup1 {
		width: 100vw;
		/* min-height: 100vh; */
		background-color: white;
		/* padding: 20px;*/
		/* border-radius: 0px 0px 40px 40px; */
	}

	#navbarNavAltMarkup1 .navbar-nav {
		border-radius: 0px 0px 40px 40px;
	}

	#navbarNavAltMarkup1 {
		margin-top: 5px;
	}
}

@media only screen and (max-width: 500px) {
	#navbarNavAltMarkup1 {
		margin-top: 0;
	}
}

/* #navbarNavAltMarkup1 .navbar-nav{
    border-radius: 0px 0px 40px 40px;
  }
}

.studentDashboardNew .icons {
  margin: 0 auto;
}

.studentDashboardNew .icons img {
  /* width: 90%; */
/* padding: 0.3rem;
  max-width: 65px;
} */

.studentDashboardNew .icons {
	margin: 0 auto;
}

.studentDashboardNew .icons img {
	width: 90%;
	padding: 0.3rem;
	max-width: 65px;
}

@media only screen and (max-width: 767px) {
	.studentDashboardNew .icons img {
		max-width: 50px;
	}
}

.card-text {
	font-size: 14px;
	color: #a12612;
}

/* .studentDashboardNew .aside .row{
  padding: 1rem;
} */

/* Student Info Start */
/* .dashboard-info{
  font-size: 100%;
} */

.dashboard-info {
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;
	margin-bottom: 1rem;
	font-size: 1.2vw;
	font-weight: bold;
}

@media only screen and (max-width: 1000px) {
	.dashboard-info {
		font-size: 1.7vw;
	}
}

@media only screen and (max-width: 600px) {
	.dashboard-info {
		font-size: 3vw;
	}
}

.dashboard-info .left-side {
	background-color: white;
	border-radius: 16px;
	/* border:10px solid rgb(228, 233, 242); */
	max-height: 11rem;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

@media only screen and (max-width: 767px) {
	.dashboard-info .left-side {
		margin-bottom: 1rem;
	}
}

.dashboard-info .left-side img {
	width: 25px;
}

.dashboard-info .right-side {
	/* margin-bottom: 1rem; */
	background-color: white;
	border-radius: 16px;
	text-align: center;
	/* padding-top: 1rem; */
	line-height: 1.5;
}

.right-side .row .col-3 .row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	text-align: center;
}

@media (max-width: 768px) {
	.right-side .row .col-12 {
		margin-bottom: 1rem;
	}
}

.dashboard-info .card-info-number {
	background-color: white;
	padding: 0.4rem;
	border-radius: 80px;
}

/* Student Info End */

.lecture-type-show {
	width: 100%;
	min-height: 42vh;
	/* margin: 0; */
}

.card-section {
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin: 0.1%;
	/* padding: 1%;
  padding-top: 1rem; */
}

/* @media only screen and (max-width:930px){
  .card-section{
    margin-left: 8%;
  }
}

@media only screen and (max-width:550px) {
  .card-section {
    margin-left: 13%;
  }
}

@media only screen and (max-width:400px) {
  .card-section {
    margin-left: 18%;
  }
} */

/* Buttons row Start*/
.buttons-row {
	padding-bottom: 1.5rem;
	/* justify-content: center; */
	-webkit-justify-content: space-evenly;
	        justify-content: space-evenly;

	width: 100%;
	padding-left: 25px;
	margin: 0;
}

.buttons-row button {
	color: black;
	border-color: #363636;
	border-radius: 40px;
	margin: 10px auto;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}

@media only screen and (max-width: 600px) {
	.buttons-row button {
		white-space: normal;
		margin: 0;
		line-height: 15px;
		padding: 2px 8px;
		font-size: 12px;
		font-weight: 500;
		display: table-cell;
		min-height: 35px;
	}
}

.buttons-row button:hover {
	background-color: #f7b719;
	color: #a12612;
	font-weight: 600;
}

.buttons-row button.selected {
	background-color: #f7b719;
	color: #a12612;
	font-weight: 600;
}

.buttons-integrated {
	background-color: #f7b719;
	color: #a12612;
	font-weight: 600;
}

.buttons-integrated-hollow {
	color: #a12612;
	border: #a12612;
	font-weight: 600;
}

/* Buttons row End*/

/* Cards section start */

.cards-item {
	/* justify-content: space-around; */
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}

@media only screen and (max-width: 768px) {
	.cards-item .dashboard-cards {
		display: -webkit-flex;
		display: flex;
		-webkit-flex-wrap: wrap;
		        flex-wrap: wrap;
		-webkit-justify-content: center;
		        justify-content: center;
	}
}

.upcoming-lectures .lecture-sequence-number {
	background-color: #88f37f;
}

.book-lectures .lecture-sequence-number {
	background-color: #f37f7f;
}

.past-lectures .lecture-sequence-number {
	background-color: #f3e37f;
}

.lecture-sequence-number {
	position: absolute;
	top: 8px;
	right: 16px;
	width: 2rem;
	height: 2rem;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	border-radius: 50%;
}

.card-button-wrap {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}

.dashboard-cards .card {
	border-radius: 20px !important;
	font-weight: bold;
	min-width: 220px;
	max-width: 280px;
	margin-bottom: 1rem;
	height: 95%;
	/* For extra white space at bottom w.rt to adjacent cards */
	box-shadow: 0px 32px 64px 0px rgba(17, 17, 17, 0.08);
}

.dashboard-cards .card p {
	margin: 1px auto;
}

.dashboard-cards .card .card-body {
	padding: 0 1rem 1rem 1rem;
}

.dashboard-cards button {
	border-radius: 30px;
	margin-top: 5px;
}

.card-lefttexts {
	font-weight: 500;
	color: 0, 0, 0, 0.75;
}

#language {
	border-radius: 15px;
	background-color: #eff0f6;
	padding-left: 5px;
	border: none;
	font-size: 11px;
}

.blurry-text {
	color: transparent;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* Cards section End */
/*React Player*/
.player-wrapper {
	position: relative;
	padding-top: 56.25%;
	/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.close-player-button {
	color: white;
	position: absolute;
	font-size: 25px;
	top: 1rem;
	right: 1rem;
	cursor: pointer;
}

/* No Lectures Present Start */
.no-lectures {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: center;
	margin: 0 auto;
	padding: 0 5%;
	font-weight: bolder;
	font-size: larger;
}

.no-lectures .diplay-texts {
	padding-top: 1rem;
}

.no-lectures img {
	max-width: 10rem;
}

/* No Lectures Present End */

/* New Subscriptions Start */

.new-subscriptions {
	min-height: 94vh;
	font-family: "DM Sans";
}

@media only screen and (max-width: 767px) {
	.new-subscriptions {
		padding: 0.5rem;
	}
}

.new-subscriptions .buttons-subscription {
	padding-bottom: 0.5rem;
	padding-top: 1rem;
}

.new-subscriptions .buttons-subscription .btn-primary:hover,
.new-subscriptions .buttons-subscription .btn-primary:focus,
.new-subscriptions .buttons-subscription .btn-primary:active,
.new-subscriptions
	.buttons-subscription
	.btn-primary:active:focus:not(:disabled):not(.disabled),
.new-subscriptions .buttons-subscription .btn:focus,
.new-subscriptions .buttons-subscription .btn:active,
.new-subscriptions .buttons-subscription .btn:hover {
	box-shadow: none !important;
	outline: 0;
}

.new-subscriptions .my-course {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	padding: 0;
}

.new-subscriptions .my-course button {
	border-radius: 0.25rem 0 0 0.25rem;
	padding: 0.1rem 2.5rem;
}

/* .new-subscriptions .my-course button:active{
    animation: none;
  } */

.new-subscriptions .all-course {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
	padding: 0;
}

.new-subscriptions .all-course button {
	border-radius: 0 0.25rem 0.25rem 0;
	padding: 0.1rem 2.5rem;
}

.new-subscriptions .card-area {
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin: 0 5vw;
}

@media only screen and (max-width: 1335px) {
	.new-subscriptions .card-area {
		margin: 0 2vw;
	}
}

@media only screen and (max-width: 1100px) {
	.new-subscriptions .card-area {
		margin: 0 1vw;
	}
}

@media only screen and (max-width: 1010px) {
	.new-subscriptions .card-area {
		margin: 0;
	}
}

@media only screen and (max-width: 1060px) {
	.new-subscriptions .card-area {
		-webkit-justify-content: space-evenly;
		        justify-content: space-evenly;
	}
}

.new-subscriptions .subscription-card {
	background-color: white;
	border-radius: 1rem;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	/* padding: 1rem 1rem 0.5rem 1rem; */
	padding: 0.5rem 1rem;
	margin: 0.5rem;
	min-width: 30rem;
	line-height: 1.4rem;
	max-width: 35rem;
}

@media only screen and (max-width: 1300px) {
	.new-subscriptions .subscription-card {
		min-width: 25rem;
		max-width: 30rem;
	}
}

@media only screen and (max-width: 767px) {
	.new-subscriptions .subscription-card {
		min-width: 20rem;
	}
}

.new-subscriptions .btn-outline-primary {
	color: black;
}

.new-subscriptions .subscription-card .head-text {
	font-weight: bold;
	font-size: 1.3rem;
}

.new-subscriptions .subscription-card .description {
	font-weight: lighter;
	font-size: 12px;
}

.new-subscriptions .subscription-card .description .tooltip {
	position: relative;
	display: inline-block;
	/* border-bottom: 1px dotted black; */
	opacity: inherit;
	font-family: "DM Sans";
}

.new-subscriptions .subscription-card .description .tooltip .tooltiptext {
	visibility: hidden;
	/* width: 120px; */
	background-color: black;
	color: #fff;
	/* text-align: center; */
	border-radius: 6px;
	padding: 0.5rem;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	margin-left: -60px;
}

.new-subscriptions .subscription-card .description .tooltip:hover .tooltiptext {
	visibility: visible;
}

.new-subscriptions .subscription-card .sessions {
	font-weight: bold;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.new-subscriptions .subscription-card .certification {
	min-height: 5rem;
	font-size: 14px;
}

.new-subscriptions .subscription-card .certification .certification-data {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	line-height: 1rem;
}

.new-subscriptions .subscription-card .certification .certification-data img {
	max-height: 1.5rem;
}

.new-subscriptions .subscription-card .pay-area {
	/* align-items: center;
    display: flex; */
	font-weight: bold;
	/* justify-content: center; */
	font-size: 1.3rem;
}

.new-subscriptions .subscription-card .pay-area .row {
	-webkit-flex-direction: column;
	        flex-direction: column;
	/* text-align:center; */
	color: rgba(0, 0, 0, 0.75);
}

.new-subscriptions .subscription-card .buttons-pay-row {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	padding-right: 2rem;
	/* padding-bottom: 1rem; */
	padding-bottom: 0.5rem;
}

.ruppee-symbol {
	font-family: "Roboto";
}

.new-subscriptions .subscription-card .buttons-pay-row button {
	padding: 3px 2rem;
	border-radius: 40px;
	font-size: 1.1rem;
}

/* Scroll Table Start*/

/* .new-subscriptions .subscription-card .table-responsive{
    overflow-x: inherit;
  } */

/* .new-subscriptions .subscription-card .subscription-table{
    background: white;
    box-shadow: 0px 0px 20px -3px rgba(143, 143, 143, .75);
    text-align: center;
    border-radius: 0.5rem;
  } */

.new-subscriptions .subscription-card .table-responsive {
	height: 4.3rem;
	overflow-y: auto;
	overflow-x: hidden;
	font-size: 13;
	margin-top: 5;
	font-weight: bold;
}

@media only screen and (max-width: 878px) {
	.new-subscriptions .subscription-card .table-responsive {
		height: auto;
	}
}

.new-subscriptions .subscription-card .subscription-table {
	background: white;
	box-shadow: 0px 0px 20px -3px rgba(143, 143, 143, 0.75);
	text-align: center;
	border: 1px solid rgba(17, 17, 17, 0.1);
	margin-bottom: 0;
}

/* Table Css Start */

.subscription-card .table-responsive::-webkit-scrollbar {
	width: 10px;
}

/* Track */
.subscription-card .table-responsive::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 0.5rem;
}

/* Handle */
.subscription-card .table-responsive::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 0.5rem;
}

/* Handle on hover */
.subscription-card .table-responsive::-webkit-scrollbar-thumb:hover {
	background: #555;
}

/* Table Css End */

/* Scroll Table End*/

.new-subscriptions .subscription-card .subscription-table thead {
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 1);
	font-weight: 400;
}

.new-subscriptions .subscription-card .subscription-table tbody {
	color: rgba(0, 0, 0, 1);
	font-weight: 500;
}
.new-subscriptions .subscription-card .subscription-table tr {
	font-size: 12px;
	line-height: 20px;
}

.new-subscriptions .subscription-card .subscription-table td {
	padding: 1;
}

.new-subscriptions .subscription-card .subscription-table td,
.new-subscriptions .subscription-card .subscription-table th {
	border: none;
	padding: 1px;
}

.new-subscriptions .subscription-card .more-info-area {
	padding: 5px 1rem 0 1rem;
}

.new-subscriptions .subscription-card .more-info-area .abc {
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	display: -webkit-flex;
	display: flex;
	line-height: 1.4rem;
}

.new-subscriptions .subscription-card .more-info-area .abc button {
	border: white;
	padding: 1px 2px;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.new-subscriptions .subscription-card .curriculum {
	font-size: 13;
	margin-top: 5;
	font-weight: bold;
}

.new-subscriptions .subscription-card .more-info-area ul {
	/* list-style-image:url(https://www.flaticon.com/svg/static/icons/svg/130/130884.svg); */
	margin-left: -20px;
	font-size: 14px;
	/* line-height: 1.9rem; */
}

.new-subscriptions .subscription-card .more-info-area li {
	font-size: 14px;
}

/* New Subscriptions End */

/* New Profile Page Start */
.new-profile {
	min-height: 88vh;
}

@media only screen and (min-width: 600px) {
	.new-profile-row {
		margin-left: 0;
		margin-right: 0;
	}
}

.new-profile .header img {
	width: 100%;
}

.new-profile .error-message {
	color: red;
}

/* New Profile Page End */

/* No Internet Start */
.no-internet .row {
	min-height: 90vh;
	-webkit-align-items: center;
	        align-items: center;
	text-align: center;
	padding: 5%;
}

.no-internet-image img {
	width: 50%;
	padding-bottom: 5%;
}

@media only screen and (max-width: 1050px) {
	.no-internet .row {
		min-height: 90vh;
	}
	.no-internet-image img {
		width: 80%;
	}
}

/* No Internet End */

/* New Checkout Area Start*/

@media only screen and (min-width: 767px) {
	.new-checkout .right .card {
		min-width: 21.5rem;
	}
}

.new-checkout .table-responsive {
	overflow-x: inherit;
}

.new-checkout .checkout-table {
	background: white;
	box-shadow: 0px 0px 20px -3px rgba(143, 143, 143, 0.75);
	text-align: center;
	/* border: 1px solid rgba(17, 17, 17, 0.1); */
	border-radius: 0.5rem;
	/* border-color: rgba(255, 255, 255, 1); */
}

.new-checkout .checkout-table thead {
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 1);
	font-weight: 400;
}

.new-checkout .checkout-table tbody {
	color: rgba(0, 0, 0, 1);
	font-weight: 500;
}

.new-checkout .checkout-table tr {
	font-size: 12px;
	line-height: 20px;
}

@media only screen and (max-width: 500px) {
	.new-checkout .checkout-table tr {
		font-size: 9px;
	}
}

.new-checkout .checkout-table td {
	padding: 1;
}

.new-checkout .checkout-table td,
.new-checkout .checkout-table th {
	border: none;
	padding: 1px;
}

.no-subscribed-courses {
	margin-top: 10vh;
	text-align: center;
}

.no-subscribed-courses img {
	max-height: 55vh;
}

.subscription-dashboard-link {
	color: black;
	text-decoration: none;
}

.subscription-dashboard-link:hover {
	color: black;
	text-decoration: none;
}

/* New Checkout Area End*/

/* Ring info Start */

.ring-info {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	padding: 0;
}

.ring-info .flex-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-flow: row nowrap;
	        flex-flow: row nowrap;
}

.ring-info .single-chart {
	width: 100%;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
}

.ring-info .circular-chart {
	display: block;
	margin: 10px auto;
	max-width: 80%;
	max-height: 250px;
}

.ring-info .circle-bg {
	fill: none;
	stroke: #eee;
	/* stroke:rgba(26, 32, 44, 0.75); */
	stroke-width: 3.8;
}

.ring-info .circle {
	fill: none;
	stroke-width: 2.8;
	stroke-linecap: round;
	-webkit-animation: progress 1s ease-out forwards;
	        animation: progress 1s ease-out forwards;
}

@-webkit-keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}

@keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}

.ring-info .circular-chart.orange .circle {
	stroke: #ffde17;
}

.ring-info .percentage {
	/* fill: #666; */
	/* fill:rgba(26, 32, 44, 0.75);
  fill:pink; */
	fill: #4e4b66;
	/* font-family: sans-serif; */
	font-size: 0.3em;
	text-anchor: middle;
}

@media only screen and (max-width: 767px) {
	.ring-info {
		display: none;
	}
}

/* Ring Info End */

/* FallBack UI Start */

.fallBack-UI {
	display: -webkit-flex;
	display: flex;
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
	min-height: 80vh;
	-webkit-align-items: center;
	        align-items: center;
}

.fallBack-UI .loaderWrapper {
	max-height: 8rem;
}

/* FallBack UI End */

/* Forgot Password Start */

.forgot-password {
	min-height: 60vh;
}

/* Forgot Password End */

/* Slot Calendar */

.makeStyles-btnWeek-9 .MuiTab-wrapper {
	width: 50% !important;
}

.makeStyles-btnWeek-9 {
	min-width: 9vw !important;
	margin-right: 7% !important;
}

.MuiTabs-scrollable {
	padding: 3% 0%;
}

.slotCal .MuiSvgIcon-root {
	background: #bee5eb;
	border-radius: 50%;
	padding: 7px;
	width: 2em !important;
	height: 2em !important;
}

#gamepad-btn {
	min-width: 50px;
	text-align: right;
}

#gamepad-btn i {
	font-size: 2em;
	color: white;
}

@media only screen and (max-width: 600px) {
	#gamepad-btn i {
		font-size: 25px;
	}
	#gamepad-btn {
		margin-left: 5vw !important;
		min-width: 35px;
		text-align: right;
		padding: 0 !important;
	}
}

.anchor-link,
.anchor-link:hover {
	text-decoration: none;
	color: inherit;
}

.thank-you {
  min-height: 95vh;
  font-size: 1rem;
  font-family: "DM Sans";
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #fbd741;
}

.inside-page {
  width: 100%;
  max-width: 520px;
  padding: 15px;
  background-color: white;
  border-radius: 1.5rem;
  padding: 2rem 3rem 1rem 3rem;
  margin: 5%;
}

.thank-you .img-logo {
  width: 80%;
  height: auto;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
  .thank-you .img-logo {
    width: 90%;
  }
}

@media only screen and (max-width: 500px) {
  .thank-you .img-logo {
    width: 100%;
  }
}

.thank-you .header {
  font-weight: 700;
}

.thank-you .button-div {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.thank-you button {
  /* border-color: #ffde17;
  background-color: #ffde17; */
  border-radius: 1rem;
  margin: 1rem;
  width: 80%;
}

.thank-you button h1 {
  margin: 0;
}

.thank-you .link,
.thank-you .link:hover,
.thank-you .link:focus,
.thank-you .link:active {
  text-decoration: none;
  color: inherit;
}

.resource-home{
    min-height: 95vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
.contactButton {
  color: #029d00;
}

.contactButton:focus {
  outline: none !important;
  border: none !important;
  color: #fbd741;
}
.contactButton span svg {
  font-size: 2.5rem;
  color: #fbd741;
  background: black;
  border-radius: 30px;
}

